<!--
 * @Author: 天勇 343975805@qq.com
 * @Date: 2022-11-30 16:18:27
 * @LastEditors: 天勇 343975805@qq.com
 * @LastEditTime: 2022-12-21 14:14:55
 * @FilePath: \website\src\views\HomeView.vue
 * @Description: 官网
-->
<template>
  <div class="home">
    <el-row>
      <el-col :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
        <div class="maskBox" @click="maskBox" id="download-box" v-if="show">
          <div style="text-align:center">
            <img src="@/assets/text3.png" alt />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- pc端 -->
    <el-row :gutter="0" class="header-nav hidden-xs-only">
      <el-col :xs="0" :sm="21" :md="21" :lg="21" :xl="21">
        <div style="display:flex;justify-content:space-between">
          <div style="display:flex;width:50%;margin-left:14%">
            <div class="grid-content" style="text-align:left;">
              <img class="header-img" style src="@/assets/kaobei.png" alt />
            </div>
            <div>
              <div
                class="grid-content header-content header-tit"
                style="color:#fff; font-size:2rem;margin-left:2%;width:120%"
              >优益分期</div>
            </div>
            <div>
              <div
                class="grid-content header-content"
                style="color:#fff;margin-left:2%;width:120%"
              >持牌金融机构·贷款更放心安全</div>
            </div>
          </div>

          <div style="display:flex;width:30%;justify-content:space-between;">
            <div
              class="grid-content header-content"
              style="color:#fff;cursor:pointer;"
              @click="shouyeTop"
            >首页</div>
            <div
              class="grid-content header-content"
              style="color:#fff;cursor:pointer;"
              @click="companyFn"
            >公司介绍</div>
            <div
              class="grid-content header-content"
              style="color:#fff;cursor:pointer;"
              @click="appFn"
            >产品介绍</div>
            <div
              class="grid-content header-content"
              style="color:#fff;cursor:pointer;"
              @click="phoneFn"
            >联系我们</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 移动端头部导航栏设置 -->
    <el-row :gutter="2" class="header-nav hidden-sm-and-up">
      <el-col :xs="3" :sm="1" :md="1" :lg="1" :xl="1" push="3">
        <div class="grid-content header-nav-img" style="text-align:left">
          <img class="header-img" src="@/assets/kaobei.png" alt />
        </div>
      </el-col>

      <el-col :xs="5" :sm="2" :md="2" :lg="2" :xl="2">
        <div class="grid-content header-content header-tit" style="color:#fff;">优益分期</div>
      </el-col>

      <el-col :xs="15" :sm="4" :md="4" :lg="4" :xl="4">
        <div class="grid-content header-content header-p" style="color:#fff">持牌金融机构·贷款更放心安全</div>
      </el-col>

      <el-col :xs="1" :sm="3" :md="3" :lg="3" :xl="3" class="hidden-sm-and-down">
        <div class="grid-content header-content" style="color:#333333">1</div>
      </el-col>

      <el-col :xs="1" class="hidden-sm-and-up" pull="2">
        <div class="grid-content menuBtn" style="color:#333333">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <img class="menuBtn-img el-dropdown-link" src="@/assets/1.png" alt />
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">首页</el-dropdown-item>
              <el-dropdown-item command="b">公司介绍</el-dropdown-item>
              <el-dropdown-item command="c">产品介绍</el-dropdown-item>
              <el-dropdown-item command="d">联系我们</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <!-- pc背景模块 -->
    <el-row id="banner" class="banner hidden-xs-only" style="color:white">
      <div style="display:flex;justify-content:space-between;padding:0 12.5%">
        <div >
          <div style="font-size:1.75rem;margin-top:13.0625rem;text-align:left">息费透明｜合规经营｜符合国家监管要求</div>
          <div style="font-size:3rem;margin-top:4.375rem;text-align:left">最高可借(元)</div>
          <div style="font-size:8.75rem;margin-top:3rem;text-align:left">200,000</div>
          <div style="font-size:1.75rem;margin-top:4.375rem;text-align:left">
            1000元用1天｜利息低至0.3元
            <span style="font-size:1.25rem;">(以最终审批利率为准)</span>
          </div>
        </div>
        <!-- <div class="banner-img" @click="appDowload" style="text-align:right">
          <img src="@/assets/xiazai.png" alt />
        </div> -->
      </div>

    
    </el-row>

    <!-- pcbanner底部下面滚动条 -->
    <el-row :gutter="2" class="banner-bottom hidden-xs-only">
      <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3" push="3">
        <div style="text-align:left;font-size:2.125rem" class="banner-btn-height">
          <img src="@/assets/tongzhi.png" alt />
        </div>
      </el-col>
      <el-col :xs="24" :sm="21" :md="21" :lg="21" :xl="21" push="1">
        <div style="text-align:left;font-size:1.5625rem;color:#fff" class="banner-btn-height">
          <p>贷款案例：用户184****4689提现20000元，已到账</p>
        </div>
      </el-col>
    </el-row>

    <!-- 移动端背景模块 -->
    <el-row id="bannerss" class="banner hidden-sm-and-up" style="color:white">
      <el-col :xs="24" class>
        <div style="margin-top:4.375rem;font-size:1.25rem">息费透明｜合规经营｜符合国家监管要求</div>
        <div style="margin-top:2.5rem;font-size:1.5rem">最高可借(元)</div>
        <div style="margin-top:1rem;font-size:3rem">200,000</div>
        <div style="margin-top:1rem;font-size:1rem">
          1000元用1天｜利息低至0.3元
          <span style="font-size:1.25rem;">(以最终审批利率为准)</span>
        </div>
      </el-col>
      <!-- <el-col :xs="24" class="hidden-sm-and-up" style="color:white">
        <div
          class="appDowload-log"
          @click="appDowload"
          style="margin:auto;cursor: pointer;margin-top:2rem"
        >
          <img src="@/assets/xiazai2.png" alt />
        </div>
      </el-col> -->
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
        <div
          class="banner-bottom-img"
          style="background-color:RGBA(241, 98, 89, 1);font-size:1.25rem"
        >
          <img style="width:2rem;height:1.3rem;color:#fff" src="@/assets/apptz.png" alt />
          <span style="color:#fff">贷款案例：用户184****4689提现20000元，已到账</span>
        </div>
      </el-col>
    </el-row>

    <!-- pc优益分期优势模块 -->
    <el-row class="advantage hidden-xs-only">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div style="font-size:2.125rem;font-weight:bold;margin-top:8.125rem">优益分期优势</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/menkanfill.png" alt />
        </div>
        <div class="advantage-tit">门槛低</div>
        <div class="advantage-p">满25-55周岁有机会申请</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/edu.png" alt />
        </div>
        <div class="advantage-tit">额度大</div>
        <div class="advantage-p">最高借款额度达20万</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/daozhangkuai.png" alt />
        </div>
        <div class="advantage-tit">到账快</div>
        <div class="advantage-p">最快5分钟到账</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/linghuo.png" alt />
        </div>
        <div class="advantage-tit">更灵活</div>
        <div class="advantage-p">最长可分24期 灵活借款</div>
      </el-col>
    </el-row>

    <!-- 移动端优益分期优势模块  -->
    <el-row class="advantage hidden-sm-and-up">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div style="font-size:2.125rem;font-weight:bold;margin-top:8.125rem">优益分期优势</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/menkanfill.png" alt />
        </div>
        <div class="advantage-tit">门槛低</div>
        <div class="advantage-p">满25-55周岁有机会申请</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/edu.png" alt />
        </div>
        <div class="advantage-tit">额度大</div>
        <div class="advantage-p">最高借款额度达20万</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/daozhangkuai.png" alt />
        </div>
        <div class="advantage-tit">到账快</div>
        <div class="advantage-p">最快5分钟到账</div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="advantage-top" style="margin:auto;margin-top:7.75rem">
          <img src="@/assets/linghuo.png" alt />
        </div>
        <div class="advantage-tit">更灵活</div>
        <div class="advantage-p">最长可分24期 灵活借款</div>
      </el-col>
    </el-row>

    <!-- pc急速放款模块 -->
    <el-row :gutter="0" class="loan hidden-xs-only">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div style="font-size:2.125rem;font-weight:bold;margin-top:8.125rem">
          简单
          <span style="color:#EB2C1F">3</span>步，极速放款
        </div>
      </el-col>

      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="loan-tit">01</div>
        <div class="loan-h">在线申请</div>
        <div class="loan-p">一分钟在线完成</div>
      </el-col>
      <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">
        <div class="loan-img">
          <img src="@/assets/pcrightLeft.png" alt />
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="loan-tit">02</div>
        <div class="loan-h">获得额度</div>
        <div class="loan-p">极速审批 最高20万额度</div>
      </el-col>
      <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">
        <div class="loan-img">
          <img src="@/assets/pcrightLeft.png" alt />
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="loan-tit">03</div>
        <div class="loan-h">提现到账</div>
        <div class="loan-p">最快当天到账</div>
      </el-col>
    </el-row>

    <!--移动端急速放款模块  -->
    <el-row :gutter="0" class="loan hidden-sm-and-up">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div style="font-size:2.125rem;font-weight:bold;margin-top:8.125rem">
          简单
          <span style="color:#EB2C1F">3</span>步，极速放款
        </div>
      </el-col>

      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="loan-tit" style="font-size:3rem">01</div>
        <div class="loan-h">在线申请</div>
        <div class="loan-p">一分钟在线完成</div>
      </el-col>
      <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">
        <div class="loan-img">
          <img src="@/assets/appRight.png" alt />
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="loan-tit" style="font-size:3rem">02</div>
        <div class="loan-h">获得额度</div>
        <div class="loan-p">极速审批 最高20万额度</div>
      </el-col>
      <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">
        <div class="loan-img">
          <img src="@/assets/appRight.png" alt />
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="loan-tit" style="font-size:3rem">03</div>
        <div class="loan-h">提现到账</div>
        <div class="loan-p">最快当天到账</div>
      </el-col>
    </el-row>

    <!-- pc公司介绍模块-->
    <el-row :gutter="0" id="company" class="company-Profile hidden-xs-only">
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" push="3">
        <div class="company-img" style="margin-top:8.125rem">
          <img src="@/assets/pctu.png" alt />
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" pull="0">
        <div class="company-Profile-content" style="margin-top:13.6875rem">
          <div class="company-top">
            <div class="yuan" style>
              <img src="@/assets/pcyuanjiao.png" alt />
            </div>
            <div class="phoneApp" style="margin-left:1rem;margin-top:4.5rem">
              <div style="color:#333333;font-size:2.125rem">About Us</div>
              <div style="color:#999999;font-size:1rem">持牌机构 正规平台</div>
            </div>
          </div>

          <div
            style="margin:3.4375rem 1.5rem 6.8rem 3.4rem; text-align:left; color:#131313;font-size:1.2rem"
          >合盈小额贷款（重庆）有限公司（以下简称：合盈小贷）成立于2013年，注册资本9500万美元，注册地重庆市渝北区。自获批以来，合盈小贷严格按照监管要求，依法合规经营，以高度的社会责任感，立足自身，为客户提供优质服务；以积极的经营态度，谋求转型，响应新形势下的市场需求，具体从引进示例股东、客户对象、经营模式、内部管理以及产品设计等五个方面扎实推进转型进程。</div>
        </div>
      </el-col>
    </el-row>

    <!-- 移动端公司介绍模块-->
    <el-row :gutter="0" id="companyss" class="company-Profile hidden-sm-and-up">
      <el-col>
        <div class="company-img">
          <img src="@/assets/appcom.png" alt />
        </div>
      </el-col>
      <el-col>
        <div class="company-content">
          <div class="company-text align:center" style="display:flex">
            <div class="company-log" style>
              <img src="@/assets/appyuanjiao.png" alt />
            </div>
            <div class style="margin-left:1rem">
              <div style="font-size:2rem;font-weight:800;text-align:left">About Us</div>
              <div style="font-size:1.25rem;color:rgba(153, 153, 153, 1);">持牌机构 正规平台</div>
            </div>
          </div>

          <div
            style="text-align:left;padding:0 1rem 1rem 2.5rem;color:rgba(153, 153, 153, 1);font-size:1.25rem"
          >合盈小额贷款（重庆）有限公司（以下简称：合盈小贷）成立于2013年，注册资本9500万美元，注册地重庆市渝北区。自获批以来，合盈小贷严格按照监管要求，依法合规经营，以高度的社会责任感，立足自身，为客户提供优质服务；以积极的经营态度，谋求转型，响应新形势下的市场需求，具体从引进示例股东、客户对象、经营模式、内部管理以及产品设计等五个方面扎实推进转型进程。</div>
        </div>
      </el-col>
    </el-row>

    <!-- pc端app介绍模块 -->
    <el-row :gutter="0" id="appContent" class="app-content hidden-xs-only">
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="app-img">
          <img src="@/assets/iphone_13_mockup_051.png" alt style="margin-top:1.2rem" />
        </div>
      </el-col>
      <el-col :xs="9" :sm="9" :md="9" :lg="9" :xl="9">
        <div class="app-log" style="text-align:left;margin-top:13rem">
          <img src="@/assets/pckaobei3.png" alt />
        </div>

        <div class="app-tit" style="text-align:left;margin-top:3.4375rem">
          <p style="color:#333333;font-size:2.125rem;font-weight:900">优益分期</p>
          <p style="color:#999999;font-size:1rem">一站式数字贷款APP</p>
        </div>

        <div
          class="phoneApp-content"
          style="margin:3.4375rem 1.5rem 6.8rem 0; text-align:left; color:#131313;font-size:1.2rem"
        >优益分期是重庆市渝北区合盈小额贷款（重庆）有限公司旗下一款一站式数字贷款app。2021年，优益分期App上线，主要提供线上小额，大额借款。2022年通过大数据分析，人工智能和机器学习技术，为市场提供资产精准匹配，精准营销等数字贷款服务。目前优益分期已累计服务3000万客户，合作平台超300家，金融贷款规模超30亿，获得客户一致好评和信赖。</div>
      </el-col>
    </el-row>

    <!-- 移动端app介绍模块 -->
    <el-row :gutter="20" id="appContentss" class="app-content hidden-sm-and-up">
      <el-col :xs="9" :sm="12" :md="12" :lg="12" :xl="12" pull="1">
        <div class="app-content-Img">
          <img src="@/assets/iPhone.png" alt style />
        </div>
      </el-col>
      <el-col :xs="15" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="app-content-logo">
          <img src="@/assets/pckaobei3.png" alt />
        </div>
        <div class>
          <p style="color:#333333;font-size:1.75rem;font-weight:900;text-align:left;">优益分期APP</p>
          <p style="color:#999999;font-size:0.6rem;;text-align:left;margin-top:0.3rem">一站式数字贷款APP</p>
        </div>

        <div
          style="color:#999999;font-size:1.25rem;text-align:left;margin-top:1.25rem;margin-right:1rem"
        >优益分期是重庆市渝北区合盈小额贷款（重庆）有限公司旗下一款一站式数字贷款app。2021年，优益分期App上线，主要提供线上小额，大额借款。2022年通过大数据分析，人工智能和机器学习技术，为市场提供资产精准匹配，精准营销等数字贷款服务。目前优益分期已累计服务3000万客户，合作平台超300家，金融贷款规模超30亿，获得客户一致好评和信赖。</div>
      </el-col>
    </el-row>

    <!-- pc端底部备案号区域 -->
    <el-row :gutter="0" id="phoneContent" class="footer hidden-xs-only">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" push="14" style="margin-top:5rem">
        <div class="content-footer">
          <div style="text-align:left">
            <img style="margin-top:0.4rem" src="@/assets/24gf-phoneLoudspeaker.png" alt />
          </div>

          <div style="text-align:left; margin-left:1rem">
            <p style="color:#fff;">联系我们(周一至周五9:00-1800)</p>
            <p style="color:#fff;">4008863608</p>
          </div>
        </div>
      </el-col>
      <el-col style="margin-top:7rem">
        <div style="color:#fff">
          合盈小额贷款（重庆）有限公司|
          <a
            href="https://beian.miit.gov.cn/"
            style="text-decoration:none;color:#fff"
          >渝ICP备15000127号</a>
        </div>
      </el-col>
    </el-row>

    <!-- 移动端底部 -->
    <el-row :gutter="0" id="phoneContentss" class="footer hidden-sm-and-up">
      <el-col>
        <div class="app-bottom">
          <div class="phoneImg">
            <img src="@/assets/24gf-phoneLoudspeaker.png" alt />
            <span style="color:#fff;">&nbsp联系我们(周一至周五9:00-18:00)</span>
          </div>

          <div style="margin-top:2rem">
            <p style="color:#fff;">4008863608</p>
          </div>
        </div>
      </el-col>
      <el-col style="margin-top:2rem">
        <div class="filingNo" style="color:#fff">
          合盈小额贷款（重庆）有限公司|
          <a
            href="https://beian.miit.gov.cn/"
            style="text-decoration:none;color:#fff"
          >渝ICP备15000127号</a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
export default {
  data() {
    return {
      show: false,
      // 安卓下载地址
      // appAnd: "https://xyz.yyfq.tech/yyqb/yyfq_v1.0.0.0.apk",
      // 苹果下载地址
      // ipaIos:"itms-services://?action=download-manifest&url=https://xyz.yyfq.tech/yyqb/plist"
    };
  },
  created() {
    // 判断手机是安卓还是苹果
    this.getUserAgent();
  },

  methods: {
    // 移动端点击事件
    handleCommand(command) {
      if (command == "a") {
        document.getElementById("bannerss").scrollIntoView();
      } else if (command == "b") {
        document.getElementById("companyss").scrollIntoView();
      } else if (command == "c") {
        document.getElementById("appContentss").scrollIntoView();
      } else if (command == "d") {
        document.getElementById("phoneContentss").scrollIntoView();
      }
    },
    // 下载方法
    appDowload() {
      this.isWeiXin();
    },
    // pc端的页面点击
    shouyeTop() {
      document.getElementById("banner").scrollIntoView();
    },
    // 公司介绍
    companyFn() {
      document.getElementById("company").scrollIntoView();
    },
    // 产品介绍
    appFn() {
      document.getElementById("appContent").scrollIntoView();
    },
    // 联系我们
    phoneFn() {
      document.getElementById("phoneContent").scrollIntoView();
    },
    /**
     * 判断 手机环境
     */
    getUserAgent() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isiOS ? "ios" : "android";
    },
    /**
     * 复制链接
     */
    copyLink() {
      let input = document.createElement("input");
      input.value = this.getUserAgent() === "ios" ? this.ipaIos : this.appAnd;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
    },

    // 苹果与安卓的下载在微信自带的浏览器中不能实现
    isWeiXin() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // return true
        this.copyLink();
        this.show = true;
      }
      //如果不是微信用其他方式下载打开弹窗复制链接去下载
      else {
        if (this.getUserAgent() === "ios") {
          this.iosDownload();
        } else {
          this.androidDownload();
        }
      }
    },
    // 安卓下载方法
    androidDownload() {
      window.location.href = this.appAnd;
    },
    // ios下载方法
    iosDownload() {
      this.$message('暂时不支持苹果下载');
      // window.location.href = this.ipaIos;
    }
  }
};
</script>
<style lang="sass" scoped>
//小于 480px  手机
@media screen and (max-width: 480px)
  // 移动端优益分期优势模块
  .banner
    background-image: url('@/assets/appbg.png') !important
    background-size: 100% 100%
    width: 100vw
    height: 27.625rem !important
  .appDowload-log
    width: 16.25rem
    height: 5.625rem
    img
      width: 100%
      height: 100%
  .advantage
    .advantage-p
      font-size: 1.25rem !important
      color: rgba(153, 153, 153, 1)
    .banner-bottom-logo
      width: 2rem !important
      height: 1.3rem !important
  // 移动端急速放款模块
  .loan
    .loan-img
      width: 1.875rem
      height: 1.875rem
      img
        width: 100%
        height: 100%
    .loan-tit
      font-size: 3rem !important
      font-weight: 900
    .loan-h
      font-size: 1.3rem !important
      font-weight: blod
    .loan-p
      font-size: 1.25rem !important
      color: rgba(153, 153, 153, 1)
  // 移动端公司介绍模块
  .company-Profile
    margin-top: 5rem
    .company-img
      width: 96vw !important
      height: 25.125rem
      margin-left: 2vw
      img
        width: 100%
        height: 100%
    .company-content
      box-shadow: 0px 2px 30px 0px rgba(19,19,19,0.2)
      width: 90vw
      margin-left: 5vw
      margin-top: -10rem
      background-color: #fff
      border-radius: 0.8rem
      .company-text
        padding: 2rem
      .company-log
        margin-top: 0.6rem
        width: 0.8rem
        height: 4rem
        img
          width: 100%
          height: 100%
  // 移动端app介绍模块
  .app-content
    margin-top: 8.125rem
    .app-content-Img
      margin-top: 1rem
      // margin-left:-5rem
      width: 21.6875rem
      height: 27.6875rem
      img
        width: 100%
        height: 100%
    .app-content-logo
      width: 4.375rem
      height: 4.375rem
      img 
        height: 100%
        width: 100%
  //移动端底部
  .footer
    min-height: 14.5rem
    font-size: 1.25rem

    .app-bottom
      margin-top: 4rem
      .phoneImg
        img
          width: 1.3rem
          height: 1.3rem
  
  // 头部导航栏设置
  .header-nav
    width: 100vw !important
    // padding:0 1rem
    padding-left: -2rem
    min-height: 5rem !important
    display: flex
    align-center: center
    .header-nav-img
      margin-top: -.5rem
      margin-left: -4rem !important
      width: 3.5rem !important
      height: 3.5rem !important
      .header-img
        width: 100%
        height: 100%
    .header-tit
      margin-top: .4rem !important
      margin-left: -2rem
      font-weight: 900
      
    .header-p
      margin-left: -8rem
      margin-top: 0.5rem !important
      font-size: 1.625rem !important
    .menuBtn
      cursor: pointer
      margin: 1rem 0 0 0 
      width: 4rem !important
      height: 0.2rem !important
      .menuBtn-img
        width: 100%
        height: 80% 
  // 遮罩层设置
  .maskBox 
    position: fixed
    min-height: 100vh
    background-color: rgba(0,0,0,.5)
    opacity: 1
    z-index: 999
    width: 100vw
    div
      // margin:auto
      height: 17.75rem
      width: 33.25rem
      margin-top: 36.25rem
      margin-left: 8rem
      img
        width: 100%
        height: 100%

    

    



// pc端
.home
  .header-nav
    background-color: #333333
    color: white
    width: 100vw
    .header-img
      margin-top: 1rem
      width: 3rem
      min-height: 3rem
      object-fit: contain
    .header-content
      margin-top: 1.25rem
    .header-tit
      font-size: 2rem
      font-weight: blod
  .bg-purple
    background: #333333
  .grid-content
    min-height: 36px
    line-height: 36px
  .banner
    background-image: url('@/assets/bgcontent.png')
    width: 100vw
    height: 61.125rem
    .banner-img
      text-align: right
      margin-top: 21.9375rem
      cursor: pointer
      text-align: right
      width: 27.0625rem !important
      height: 9rem !important
      img
        width: 100%
        height: 100%
  .banner-bottom
    height: 3.5rem
    background-color: #EF5349
    width: 100vw
    .banner-bottom-content
      color: #fff
      display: flex
      align-content: center
    .banner-btn-height
      margin-top: 0.75rem
  .advantage
    .demo
      display: flex
    .advantage-top
      text-align: center
      vertical-align: middle
      margin-top: 7.75rem
      width: 5rem
      height: 5rem
      margin: auto
      img
        width: 100%
        height: 100%
    .advantage-tit
      margin-top: 1.6875rem
      font-size: 1.6875rem
      color: black
      font-weight: blod
    .advantage-p
      margin-top: 1rem
      color: #999999
      font-size: 1.6875rem
  .loan
    .loan-tit
      margin-top: 6.0625rem
      font-size: 6.25rem
      color: #EB2C1F
      font-weight: blod
    .loan-img
      margin-top: 9.4375rem
    .loan-h
      font-size: 2.25rem
      margin-top: 1rem
      font-weight: 900
    .loan-p
      font-size: 1.75rem
      margin-top: 1rem
      color: #999999
  .company-Profile
    .company-img 
      width: 50vw
      height: 44.5625 
      img
        width: 100%
        height: 100%

    .company-Profile-content
      height: 33.625rem
      width: 40vw
      box-shadow: 0px 2px 30px 0px rgba(19,19,19,0.2)
      background-color: #fff
      border-radius: 1.25rem
      .company-top
        display: flex
        margin-left: 2rem
        .yuan
          margin-top: 4.5rem
          height: 4.375rem
          width: 1rem
          img 
            width: 100%
            height: 100%
 

  // app介绍模块
  .app-content
    position: absolute
    z-index: 1
    .app-img
      height: 55.375rem
      img
        width: 100%
        height: 100%
    .app-log
      width: 7.625rem
      height: 7.625rem
      img
        width: 100%
        height: 100%
  .footer
    margin-top: 48rem
    min-height: 18.75rem
    background-color: #EB2C1F
    .content-footer
      display: flex
</style>
