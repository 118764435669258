/*
 * @Author: 天勇 343975805@qq.com
 * @Date: 2022-11-30 16:18:27
 * @LastEditors: 天勇 343975805@qq.com
 * @LastEditTime: 2022-12-09 09:03:04
 * @FilePath: \website\src\main.js
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './store/rem.js'
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
